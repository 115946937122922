<template>
  <div>
    <el-table
      :data="workCheckData.list"
      style="width: 100%;overflow: auto"
      :header-cell-style="{background:'#F9F9FB',color:'#606266'}"
      ref="table"
    >
      <el-table-column
        type="index"
        width="50"
        label="行号">
      </el-table-column>
      <el-table-column
        prop="ofoworkName"
        label="活动">
      </el-table-column>
      <el-table-column
        prop="checkworkName"
        label="核销活动">
      </el-table-column>
      <el-table-column
        prop="checkTotal"
        label="核销金额">
        <template slot-scope="scope">
          {{scope.row.checkTotal | comdify}}
        </template>
      </el-table-column>
    </el-table>
    <el-row style="margin-top:16px">
      <el-col :span="12">未核销金额</el-col>
      <el-col :span="12">{{workCheckData.openTotal | comdify}}</el-col>
    </el-row>
    <el-row style="margin-top:16px">
      <el-col :span="12">总计金额</el-col>
      <el-col :span="12">{{workCheckData.ptotal | comdify}}</el-col>
    </el-row>
    <el-button size="mini" style="margin-top:16px" type="primary" @click="openCheck" icon="el-icon-plus" v-if="handleDutys(ofoFlowWork.dutys, 'addType') && ofoFlowWork.checkType == '2' && documentstatus != '0' && workCheckData.checkStatus != '0'" >核销</el-button>
    <!-- <el-table
      :data="fileList"
      style="width: 100%;overflow: auto"
      :header-cell-style="{background:'#F9F9FB',color:'#606266'}"
      ref="table"
      v-show="disabled"
    >
      <el-table-column
        type="index"
        width="50"
        label="序号">
      </el-table-column>
      <el-table-column
        prop="name"
        label="附件类型">
      </el-table-column>
      <el-table-column
        prop="remarks"
        label="上传要求及说明">
      </el-table-column>
    </el-table> -->
    <el-dialog
      title="核销"
      :visible.sync="dialogFormVisible"
      append-to-body
      width="30%" >
      <div slot="title">
        核销 {{workCheckData.name}} （未核销金额：{{workCheckData.openTotal | comdify}}）
      </div>
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        class="demo-ruleForm"
        label-position="right"
        label-width="110px"
      >
        <el-form-item label="核销活动" prop="checkWorkId">
          <el-select v-model="ruleForm.checkWorkId" placeholder="请选择核销活动" style="width:100%" filterable @change="changeCheckWork">
            <el-option v-for="(item, index) in chooseCheckWorkList" :key="index" :value="item.ofoWorkId" :label="item.name">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="本次核销金额" prop="checkTotal">
          <el-input-number v-model="ruleForm.checkTotal" placeholder="请输入本次核销金额" controls-position="right" :max="max" :precision="2" :step="0.01" style="width: 100%"></el-input-number>
          <!-- <el-input v-model="ruleForm.checkTotal" type="Number"></el-input> -->
        </el-form-item>
        <el-form-item label="备注" prop="remarks">
          <el-input v-model="ruleForm.remarks" placeholder="请输入备注" type="textarea"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitForm" :loading="isLoading">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import url from '@/config/url'
import qs from 'qs'
  export default {
    props: {
      ofoWorkId: [Number, String],
      workCheckData: Object,
      ofoFlowWork: Object,
      documentstatus: [Number, String]
    },
    data() {
      return {
        list: [],
        dialogFormVisible: false,
        select: '',
        ruleForm: {
          checkTotal: "",
          checkWorkId: "",
          remarks: ""
        },
        rules: {
          checkTotal: [{ required: true, message: "请输入本次核销金额", trigger: "submit" }],
          checkWorkId: [{ required: true, message: "请选择核销活动", trigger: "submit" }]
        },
        chooseCheckWorkList: [],
        isLoading: false,
        max: 0
      }
    },
    methods: {
      changeCheckWork(e) {
        this.chooseCheckWorkList.forEach((item, index) => {
          if(item.ofoWorkId == e) {
            this.ruleForm.checkTotal = (this.chooseCheckWorkList[index].openTotal < this.workCheckData.openTotal) ? this.chooseCheckWorkList[index].openTotal : this.workCheckData.openTotal
            this.max = this.ruleForm.checkTotal
          }
        })
      },
      comdify(n) {
        if(!n) return '0.00';
        n = Number(n).toFixed(2)
        // console.log(n);
        let res01 = n.split(".")[0];
        let res02 = n.split(".")[1];
        return `${res01}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",") + `.${res02}`;
      },
      openCheck() {
        this.dialogFormVisible = true
        this.$http.get(url.getChooseCheckWorkList, {params: {
          ofoWorkId: this.ofoWorkId
        }}).then(res => {
          if(res.data.code == 200) {
            this.chooseCheckWorkList = res.data.data
            if(this.chooseCheckWorkList.length) {
              this.chooseCheckWorkList.forEach(item => {
                item.name = item.name + ' ( ' + this.comdify(item.openTotal) + ' )'
              })
              this.ruleForm.checkWorkId = this.chooseCheckWorkList[0].ofoWorkId
              this.changeCheckWork(this.ruleForm.checkWorkId)
              // this.ruleForm.checkTotal = (this.chooseCheckWorkList[0].openTotal < this.workCheckData.openTotal) ? this.chooseCheckWorkList[0].openTotal : this.workCheckData.openTotal

            }


          }
        })
        this.$nextTick(() => {
          // 清除表单校验效果
          this.$refs.ruleForm.clearValidate();
        })
      },
      submitForm() {
        this.$refs['ruleForm'].validate((valid) => {
          if (valid) {
            this.isLoading = true
            this.$http.post(url.addWorkCheck, qs.stringify({...this.ruleForm, ofoWorkId: this.ofoWorkId})).then(res => {
              this.isLoading = false
              this.dialogFormVisible = false
              if(res.data.code === 200) {
                this.$message.success(res.data.msg)
                this.$emit('getWorkCheckList', {ofoWorkId: this.ofoWorkId, isRefresh: true})
              }
            })
          }
        })
      }
    },
  }
</script>

<style lang="scss" scoped>

</style>
